
.control-docnum[data-v-748fb4d0] {
  width: 16ch;
}
.control-customer[data-v-748fb4d0] {
  width: 45ch;
}
.spec-filter[data-v-748fb4d0] {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjMycHQiIGhlaWdodD0iMzJwdCIgdmlld0JveD0iMCAwIDMyIDMyIiB2ZXJzaW9uPSIxLjEiPg0KPGcgaWQ9InN1cmZhY2UxIj4NCjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoODAlLDAlLDAlKTtmaWxsLW9wYWNpdHk6MTsiIGQ9Ik0gNSA0IEwgNSA2LjM0Mzc1IEwgNS4yMTg3NSA2LjYyNSBMIDEzIDE2LjM0Mzc1IEwgMTMgMjggTCAxNC41OTM3NSAyNi44MTI1IEwgMTguNTkzNzUgMjMuODEyNSBMIDE5IDIzLjUgTCAxOSAxNi4zNDM3NSBMIDI2Ljc4MTI1IDYuNjI1IEwgMjcgNi4zNDM3NSBMIDI3IDQgWiBNIDcuMjgxMjUgNiBMIDI0LjcxODc1IDYgTCAyMy4xMjUgOCBMIDguODc1IDggWiBNIDEwLjQ2ODc1IDEwIEwgMjEuNTMxMjUgMTAgTCAxNy4yMTg3NSAxNS4zNzUgTCAxNyAxNS42NTYyNSBMIDE3IDIyLjUgTCAxNSAyNCBMIDE1IDE1LjY1NjI1IEwgMTQuNzgxMjUgMTUuMzc1IFogIi8+DQo8L2c+DQo8L3N2Zz4NCg==);
}
.spec-paste[data-v-748fb4d0] {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjMycHQiIGhlaWdodD0iMzJwdCIgdmlld0JveD0iMCAwIDMyIDMyIiB2ZXJzaW9uPSIxLjEiPg0KPGcgaWQ9InN1cmZhY2UxIj4NCjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoODAlLDAlLDAlKTtmaWxsLW9wYWNpdHk6MTsiIGQ9Ik0gMTUgMyBDIDEzLjczODI4MSAzIDEyLjg0NzY1NiAzLjg5MDYyNSAxMi40MDYyNSA1IEwgNSA1IEwgNSAyOCBMIDEzIDI4IEwgMTMgMzAgTCAyNyAzMCBMIDI3IDE0IEwgMjUgMTQgTCAyNSA1IEwgMTcuNTkzNzUgNSBDIDE3LjE1MjM0NCAzLjg5MDYyNSAxNi4yNjE3MTkgMyAxNSAzIFogTSAxNSA1IEMgMTUuNTU0Njg4IDUgMTYgNS40NDUzMTMgMTYgNiBMIDE2IDcgTCAxOSA3IEwgMTkgOSBMIDExIDkgTCAxMSA3IEwgMTQgNyBMIDE0IDYgQyAxNCA1LjQ0NTMxMyAxNC40NDUzMTMgNSAxNSA1IFogTSA3IDcgTCA5IDcgTCA5IDExIEwgMjEgMTEgTCAyMSA3IEwgMjMgNyBMIDIzIDE0IEwgMTMgMTQgTCAxMyAyNiBMIDcgMjYgWiBNIDE1IDE2IEwgMjUgMTYgTCAyNSAyOCBMIDE1IDI4IFogIi8+DQo8L2c+DQo8L3N2Zz4NCg==);
}
.spec-paste-spec[data-v-748fb4d0] {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjY0cHQiIGhlaWdodD0iNjRwdCIgdmlld0JveD0iMCAwIDY0IDY0IiB2ZXJzaW9uPSIxLjEiPg0KPGcgaWQ9InN1cmZhY2UxIj4NCjxwYXRoIHN0eWxlPSIgc3Ryb2tlOm5vbmU7ZmlsbC1ydWxlOm5vbnplcm87ZmlsbDpyZ2IoODAlLDAlLDAlKTtmaWxsLW9wYWNpdHk6MTsiIGQ9Ik0gMzAgNiBDIDI3LjQ4MDQ2OSA2IDI1LjY5NTMxMyA3Ljc4MTI1IDI0LjgxMjUgMTAgTCAxMCAxMCBMIDEwIDU2IEwgMjYgNTYgTCAyNiA2MCBMIDU0IDYwIEwgNTQgMjggTCA1MCAyOCBMIDUwIDEwIEwgMzUuMTg3NSAxMCBDIDM0LjMwNDY4OCA3Ljc4MTI1IDMyLjUxOTUzMSA2IDMwIDYgWiBNIDMwIDEwIEMgMzEuMTA5Mzc1IDEwIDMyIDEwLjg5MDYyNSAzMiAxMiBMIDMyIDE0IEwgMzggMTQgTCAzOCAxOCBMIDIyIDE4IEwgMjIgMTQgTCAyOCAxNCBMIDI4IDEyIEMgMjggMTAuODkwNjI1IDI4Ljg5MDYyNSAxMCAzMCAxMCBaIE0gMTQgMTQgTCAxOCAxNCBMIDE4IDIyIEwgNDIgMjIgTCA0MiAxNCBMIDQ2IDE0IEwgNDYgMjggTCAyNiAyOCBMIDI2IDUyIEwgMTQgNTIgWiBNIDMwIDMyIEwgNTAgMzIgTCA1MCA1NiBMIDMwIDU2IFogTSA0MCAzNi44MTI1IEwgMzggNDEuMzc1IEwgMzMgNDEuODEyNSBMIDM2LjgxMjUgNDUuMTg3NSBMIDM1LjYyNSA1MCBMIDQwIDQ3LjYyNSBMIDQ0LjM3NSA1MCBMIDQzLjE4NzUgNDUuMTg3NSBMIDQ3IDQxLjgxMjUgTCA0MiA0MS4zNzUgWiAiLz4NCjwvZz4NCjwvc3ZnPg0K);
}
.inpradio[data-v-748fb4d0] {
  display: flex;
  align-items: center;
  margin-left: 15%;
}
.inpradio span[data-v-748fb4d0] {
  margin-left: 16px;
}
.toolbar-hint[data-v-748fb4d0] {
  margin-left: 32px;
  font-size: 14px;
  line-height: 1.42857143;
  color: navy;
}
