
.toolbarpanel[data-v-2ea3ea26] {
   display: grid;
   grid-template-rows: 1fr auto;
   grid-template-areas: "panel" "title";
   min-height: 84px;
   border-right: 1px solid gray;
   background-color: #efefef;
}
.panel_content[data-v-2ea3ea26] {
   grid-area: panel;
   display: flex;
   align-items: flext-start''
}
.panel_title[data-v-2ea3ea26] {
   grid-area: title;
   font-size: 7pt;
   text-align: center;
   /*border-top: 1px solid gray;
   background-color: #dddddd;*/
}
