
.control-group[data-v-da88ced6] {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.field-nomen-code[data-v-da88ced6] {
    width: 20%;
}
.field-nomen-name[data-v-da88ced6] {
    width: 80%;
}
.link-container[data-v-da88ced6] {
    margin-top: 23px;
}
.link[data-v-da88ced6] {
    cursor: pointer;
}
.error-message[data-v-da88ced6] {
    text-align: center;
}
