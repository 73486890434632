.v-autocomplete-input {
    background-color: #fff;
    display: block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.v-autocomplete-list {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 100;
}

.v-autocomplete-list-item {
    cursor: pointer;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    margin-bottom: 8px;
}

.v-autocomplete-list-item:last-child {
    margin-bottom: 0px;
}

.v-autocomplete-item-active {
    font-weight: bolder;
}