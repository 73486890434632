
.tabulator {
  background-color: transparent;
}
.tabulator_datactl {
  display: flex;
}
.resultHint {
  margin-left: 16px;
}
.resultHint_success {
  color: green;
}
.resultHint_error {
  color: red;
}
.resultHint_visible {
  opacity: 1;
  transition: opacity 0.5s ease 0s;
}
.resultHint_hidden {
  opacity: 0;
  transition: opacity 3s ease 0s;
}
