
.widetable-container[data-v-89715664] {
    width: 100%;
    overflow-x: auto;
}
.widetable-wrap[data-v-89715664] {
    display: inline-block;
}
.eit-container[data-v-89715664] {
    padding: 0 8px;
    /*background-color: #c4e3f3; */
}
.eit-header[data-v-89715664] {
    display: flex;
}
.eit-controls[data-v-89715664] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 24px;
}
.eit-ctlr-group[data-v-89715664] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 470px; */
}
.eit-file-selector[data-v-89715664] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    padding: 8px 16px;
    border: 3px ridge gray;
    border-radius: 8px;
    box-shadow: 5px 5px 5px darkgray;
    /*background-color: papayawhip;*/
}
.eit-file-title[data-v-89715664] {
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
}
#file-selecter[data-v-89715664] {
    font-size: 16px;
    font-weight: normal;
    color: blue;
    cursor: pointer;
}
#file-selecter.hover[data-v-89715664] {
    background-color: #afd9ee;
    font-weight: lighter;
}
.eit-drop-selector[data-v-89715664] {
    padding: 18px 8px;
    background-color: #afd9ee;
    border: 1px solid gray;
    border-radius: 6px;
}
.eit-drop-selector.hover[data-v-89715664] {
    background-color: #5fa2db;
    font-weight: bolder;
}
.ctrl-group[data-v-89715664] {
    margin-right: 16px;
    width: 215px;
}
.sheetname[data-v-89715664] {
    /*width: 250px;*/
}
.labelgroup[data-v-89715664] {
    display: flex;
    align-items: flex-start;
    color: navy;
}
.hiden_grid[data-v-89715664] {
    display: none;
}
.rownumbers[data-v-89715664] {
  display: flex;
  align-items: baseline;
}
.row_num_label[data-v-89715664] {
  margin: 0 4px;
}
.xlsdata[data-v-89715664] {
  margin-top: 8px;
}
.more_src[data-v-89715664] {
  margin-left: 36px;
  font-size: small ;
  color: dodgerblue;
  border-bottom: 1px dotted dodgerblue;
  cursor: pointer;
}
@media (max-width: 1366px) {
.eit-file-title[data-v-89715664] {
    font-size: 16px;
}
}


