
.link-panel[data-v-4dac0900], .link-container[data-v-4dac0900] {
  display: flex;
  align-items: center;


  /*border: 3px ridge gray ;
  border-left: 0px solid transparent ;
  border-right: 0px solid transparent ;*/
}
.link-container[data-v-4dac0900] {
    flex-wrap: nowrap;
    justify-content: space-between;
}
.link-panel[data-v-4dac0900] {
    flex-wrap: wrap;
}
.path-delim[data-v-4dac0900] {
  font-family: "Courier New";
}
.link-block[data-v-4dac0900] {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.link-block:last-child .path-delim[data-v-4dac0900] {
  display: none;
}
.speed-btn-container[data-v-4dac0900] {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 24px;
}
.speed-btn[data-v-4dac0900] {
    font-size: 1.2em;
    font-weight: bolder;
    color: #c00;
    width: 28px;
    height: 28px;
}
