
.v-autocomplete-input {
  width: 450px;
}
.autosuggest-container input {
  width: 450px;
  padding: 0.5rem;
}
.autosuggest-container ul {
  width: 100%;
  color: rgba(30, 39, 46, 1);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
}
.autosuggest-container li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
.autosuggest-container li:hover {
  cursor: pointer;
}
.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 450px;
}
.autosuggest-list-container {
  position: absolute;
  width: 450px;
  z-index: 100;
  background-color: #eeeeee;
  border: 0px solid #ccc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
#autosuggest {
  width: 100%;
  display: block;
}
.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178, 0.2);
}
