
.ng-container[data-v-3c7b9653] {
    /*width: 50%;*/
}
.ng-dtl[data-v-3c7b9653] {
    width: 100%;
    border-top: 1px solid darkgray;
}
.ng-dtl__error[data-v-3c7b9653] {
    padding: 32px;
    font-size: 20px;
    font-weight: bolder;
    color: red;
    text-align: center;
}
.ng-dtl__info[data-v-3c7b9653] {
    padding: 16px 16px;
}
.ng-dtl__title[data-v-3c7b9653] {
    font-size: 20px;
    text-align: center;
}
.ng-dtl__name[data-v-3c7b9653] {
    font-weight: bolder;
    color: navy;
}
.ng-dtl__block[data-v-3c7b9653] {
    margin-top: 16px;
}
.ng-dtl__path[data-v-3c7b9653] {
}
.ng-dtl__tbls[data-v-3c7b9653] {
/*      display: flex;
    justify-content: space-around;  */
}
.nomencount[data-v-3c7b9653] {
    margin-left: 16px;
    font-size: 1.2em;
    font-weight: bolder;
    color: navy;
}
