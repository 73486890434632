
.help-group[data-v-306a7cf9] {
  margin-top: 16px;
}
.table-help[data-v-306a7cf9] {
  margin-top: 16px;
}
.td-templ[data-v-306a7cf9] {
  padding: 4px 8px;
  color: navy;
  font-weight: bold;
}
.td-desc[data-v-306a7cf9] {
  padding: 4px 0px 4px 8px;
}
