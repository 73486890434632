
.fv-container[data-v-fde57f46] {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-left: 16px;
  padding-right: 8px;
}
.fv-container label[data-v-fde57f46] {
  margin-right: 4px;
}
.new-item[data-v-fde57f46] {
  color: grey;
  font-style: italic;
}
