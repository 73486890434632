
.srccell[data-v-6424b51c] {
  padding: 4px 8px 4px 4px;
}
.srctitle[data-v-6424b51c] {
  font-weight: bold;
}
.srcheader[data-v-6424b51c] {
  text-align: center;
  font-weight: bold;
}
.nomen_selector_table[data-v-6424b51c] {
  height: 440px ;
}

