
.dkc-modal-backdrop[data-v-57e69291] {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.3);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}
.dkc-modal[data-v-57e69291] {
   display: flex;
   flex-direction: column;
   max-height: 99vh;
   overflow-x: auto;
   background: #FFFFFF;
   box-shadow: 2px 2px 20px 1px;
}
.modal-header[data-v-57e69291] {
   padding: 15px;
   display: flex;
}
.modal-header[data-v-57e69291] {
   border-bottom: 1px solid #eeeeee;
   color: #4AAE9B;
   justify-content: space-between;
}
.modal-dlg-footer[data-v-57e69291] {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1ft 1fr ;
  grid-template-areas: "extra_buttons standart_buttons" ;
  border-top: 1px solid #eeeeee;
}
.standart_buttons[data-v-57e69291] {
  text-align: right;
  margin: 8px 16px 12px 0;
}
.extra_buttons[data-v-57e69291] {
  margin: 8px 0 12px 16px;
  text-align: left;
}
.modal-body[data-v-57e69291] {
   position: relative;
   padding: 20px 10px;
}
.btn-close[data-v-57e69291] {
   border: none;
   font-size: 20px;
   padding: 20px;
   cursor: pointer;
   font-weight: bold;
   color: #4AAE9B;
   background: transparent;
}
.btn-green[data-v-57e69291] {
   color: white;
   background: #4AAE9B;
   border: 1px solid #4AAE9B;
   border-radius: 2px;
}
