
.ng-container[data-v-6773b9f5] {
}
.ng-tree[data-v-6773b9f5] {
  width: 100%;
  border: 1px solid darkgray;
  overflow: scroll ;
}


