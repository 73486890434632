
.sol_container {
   display: grid;
   /*margin: 0;*/
   height: 100%;
   max-height: 100%;
   grid-template-rows: auto 1fr;
   grid-template-areas: "header" "content";
}
.sol_header {
   grid-area: header;
}
.sol_content {
   grid-area: content;
}
.sol_tbl_wrap {
   height: 100%;
   max-height: 100%;
}
.state_toolbar_panel {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly ;
   height: 100%;
}
.toggle_control {
   margin-left: 12px;
}
.toggle_label {
   width: 32ch ;
   text-align: right;
}
.toggle_block {
   margin: 0;
   display: flex;
   align-items: center ;
   padding: 0 8px;
}
