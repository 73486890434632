
.twin_box[data-v-992e1a3c] {
  width: 100%;
  height: 80vh;
}
.prep_box[data-v-992e1a3c] {
  width: 500px;
  margin: 10% auto 0 auto;
  padding: 60px;
  border: 2px solid black;
  text-align: center;
  font-size: 40px;
  font-weight: bolder;
  color: #333;
}
.error_box[data-v-992e1a3c] {
  width: 500px;
  margin: 10% auto 0 auto;
  padding: 60px;
  border: 2px solid black;
  text-align: center;
  font-size: 40px;
  font-weight: bolder;
  color: red;
}
