
.app_container[data-v-371fa56a] {
    display: grid;
    margin: 0;
    max-height: 100vh;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "header" "content" "debug";
}
.app_hidden[data-v-371fa56a] {
    display: block;
}
header[data-v-371fa56a] {
    grid-area: header;
    max-width: 99vw;
}
.main_view[data-v-371fa56a] {
    grid-area: content;
}
.debug_area[data-v-371fa56a] {
    grid-area: debug;
    display: none;
    padding-top: 50px;
}
.dkc-navbar-header[data-v-371fa56a] {
   display: flex;
   justify-content: space-between;
   align-items: center;
   max-width: 98.5vw;
   padding-left: 24px;
   padding-right: 48px;
}
.brand[data-v-371fa56a] {
  display: flex;
  justify-content: flex-start ;
  align-items: center;
}
.username[data-v-371fa56a] {
  color: #cc0000;
  font-weight: bolder;
}
.caption[data-v-371fa56a] {
   display: block;
   max-width: 98.5vw;
   text-align: center;
}
#mainMenu[data-v-371fa56a] {
  width: 100%;
}

