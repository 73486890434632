
.tab[data-v-7f5c9f1a] {
   display: inline-block;
   padding: 4px 8px;
   list-style-type: none;
   border-left: 1px gray solid ;
   border-top: 1px gray solid ;
   border-right: 1px gray solid ;
   border-radius: 4px 4px 0 0;
   cursor: pointer;
   background-color: #dddddd;
}
.active_tab[data-v-7f5c9f1a] {
   font-weight: bold;
   border-left: 1px black solid ;
   border-top: 1px black solid ;
   border-right: 1px black solid ;
   background-color: transparent;
}
