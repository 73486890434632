.splitpanes__splitter {
    background-color: #ccc;
    position: relative;
}

.splitpanes__splitter:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 0.7s;
/*    background-color: rgba(50, 50, 50, 0.5);  */
    background: linear-gradient(to right,   #aaa 0%,
                                            #777 50%,
                                            #bbb 100%);
    opacity: 0;
    z-index: 1;
}

.splitpanes__splitter:hover:before {
    opacity: 1;
}

.splitpanes--vertical > .splitpanes__splitter:before {
    left: -3px;
    right: -3px;
    height: 100%;
}

.splitpanes--horizontal > .splitpanes__splitter:before {
    top: -2px;
    bottom: -2px;
    width: 100%;
}