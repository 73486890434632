
.nsh_container[data-v-ff399890] {
  margin-bottom: 8px;
}
table[data-v-ff399890] {
  width: 100%;
}
tr[data-v-ff399890] {
  padding-top: 2px;
  padding-bottom: 2px;
}
td[data-v-ff399890] {
  padding: 6px 4px;
}
.header[data-v-ff399890] {
  font-weight: bolder;
}
.title[data-v-ff399890] {
  width: 80px;
  padding-left: 4px;
  font-weight: bold;
  cursor: pointer;
}
.firm[data-v-ff399890] {
  width: 200px;
}
.code[data-v-ff399890],
.mark[data-v-ff399890] {
  width: 180px;
}
.name[data-v-ff399890] {
  width: 340px;
}
.search_control_group[data-v-ff399890] {
  display: flex;
}
.search_control_group button[data-v-ff399890] {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.search_control_group img[data-v-ff399890] {
  width: 30px;
  height: 30px;
}
.bottom_border[data-v-ff399890] {
  border-bottom: #ccc 1px solid;
}
.top_toolbar[data-v-ff399890] {
  display: flex;
  justify-content: space-between;
}
button span[data-v-ff399890] {
  padding: 0 12px;
}
button span[data-v-ff399890]:hover {
  color: navy ;
  font-weight: bolder;
}
