
.search-container[data-v-2420d9e4] {
  display: flex;
  align-items: center;
  margin-left: 16px;
}
.close-btn[data-v-2420d9e4] {
    margin-left: -10px;
    font-size: 2em;
    font-weight: bolder;
    color: #CC0000;
    transform: rotate(45deg);
    border-color: transparent;
}

