
.component[data-v-c41ac990] {
  max-width: 99vw;
}
.eit-container[data-v-c41ac990] {
  padding: 0 8px;
  /*background-color: #c4e3f3; */
}
.eit-header[data-v-c41ac990] {
  display: flex;
}
.eit-controls[data-v-c41ac990] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 24px;
}
.eit-ctlr-group[data-v-c41ac990] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 470px;
}
.eit-file-selector[data-v-c41ac990] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  padding: 8px 16px;
  border: 3px ridge gray;
  border-radius: 8px;
  box-shadow: 5px 5px 5px darkgray;
  /*background-color: papayawhip;*/
}
.eit-file-title[data-v-c41ac990] {
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
}
#file-selecter[data-v-c41ac990] {
  font-size: 16px;
  font-weight: normal;
  color: blue;
  cursor: pointer;
}
#file-selecter.hover[data-v-c41ac990] {
  background-color: #afd9ee;
  font-weight: lighter;
}
.eit-drop-selector[data-v-c41ac990] {
  padding: 18px 8px;
  background-color: #afd9ee;
  border: 1px solid gray;
  border-radius: 6px;
}
.eit-drop-selector.hover[data-v-c41ac990] {
  background-color: #5fa2db;
  font-weight: bolder;
}
.ctrl-group[data-v-c41ac990] {
  margin-right: 16px;
  width: 215px;
}
.result-container[data-v-c41ac990] {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.rc-tree-container[data-v-c41ac990],
.rc-grid-container[data-v-c41ac990] {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.rc-tree-container[data-v-c41ac990] {
  width: 30%;
}
.rc-grid-container[data-v-c41ac990] {
  width: 69%;
}
#tree[data-v-c41ac990] {
  height: 100%;
  border: 1px solid silver;
}
.it_band[data-v-c41ac990] {
  margin-top: 16px;
  padding-top: 4px;
}
summary[data-v-c41ac990],
.band-label[data-v-c41ac990] {
  margin-bottom: 0;
  padding: 8px;
  width: 100%;
  font-weight: bold;
  color: #fcf8e3;
  background-color: #333333;
}
