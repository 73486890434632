
.ldrbkg[data-v-758255be] {
      background-color: gray;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 150;
      opacity: 0.5;
}
.loader[data-v-758255be]{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        z-index: 200;
}
.loader .face[data-v-758255be]{
        position: absolute;
        border:2px solid transparent;
}
.prots[data-v-758255be] {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 40px;
        font-weight: bolder;
        color: #ed0000;
}
.stage[data-v-758255be] {
        width: 600%;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 80%);
        padding: 16px;
        border: 1px solid #0674d296;
        text-align: center;
        font-size: 20px;
        color: #40a9de;
        background-color: lemonchiffon;
}
.loader .face.face1[data-v-758255be]{
        top: 0;
        left:0;
        right: 0;
        bottom: 0;
        /*box-shadow:0 0 15px rgba(240,240,0,1);*/
        border-radius: 50%;
        border-left: 2px solid #ffff00;
        border-top: 2px solid #ffff00;
        animation: ani1-758255be 3s linear infinite;
}
.loader .face.face2[data-v-758255be]{
        top: 30px;
        left:30px;
        right: 30px;
        bottom: 30px;
/*        box-shadow:0 0 10px rgba(3,170,240,1);*/
        border-radius: 50%;
        border-right: 2px solid #cc0000;
        border-bottom: 2px solid #cc0000;
        animation: ani2-758255be 3s linear infinite;
}
.loader .face .circle[data-v-758255be]{
        position: absolute;
        top: calc(50% - 1px);
        left: 50%;
        width: 50%;
        height: 2px;
        transform-origin: left;
}
.loader .face.face1 .circle[data-v-758255be]{
        transform: rotate(-45deg);
}
.loader .face.face2 .circle[data-v-758255be]{
        transform: rotate(-45deg);
}
.loader .face .circle[data-v-758255be]:before{
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        top: -4px;
        right: -6px;
}
.loader .face.face1 .circle[data-v-758255be]:before{
        background:#ffff00;
        box-shadow: 0 0 20px #ff0,0 0 40px #ff0,0 0 60px #ff0,0 0 80px #ff0,
        0 0 100px #ff0,0 0 0 5px rgba(255,255,0,.1);
}
.loader .face.face2 .circle[data-v-758255be]:before{
        background:#ed0000;
        box-shadow: 0 0 20px #cc0000,0 0 40px #cc0000,0 0 60px #cc0000,0 0 80px #cc0000,
        0 0 100px #cc0000,0 0 0 5px rgba(204,0,0, .1);
}
@keyframes ani1-758255be{
0%{
            transform:rotate(0deg);
}
100%{
            transform:rotate(360deg);
}
}
@keyframes ani2-758255be{
0%{
            transform:rotate(0deg);
}
100%{
            transform:rotate(-360deg);
}
}
