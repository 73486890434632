
    /*$context-menu-border-radius: 4px;*/
.context-menu-container {
        /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
        font-size: .8em;*/
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        color: #333;
        position: absolute;
        user-select: none;
        z-index: 100;
}
.context-menu {
      /*background: #e4e4e4;*/
      border-radius: 4px;
      /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);*/
}
.context-menu  ul {
      background: #e4e4e4;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      margin: 0;
      padding: 0;
      list-style: none;
}
.context-menu  ul:first-child {
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
}
.context-menu  ul:last-child {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
}
.context-menu li {
      position: relative;
      padding: .5em 0.3em;
      cursor: pointer;
}
.cm-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      white-space: nowrap;
}
.begin-menu-group {
      height: 4px;
      border-bottom: 2px groove #cccccc;
}
.context-menu li img {
      margin-right: 0.3em;
}
.cm-item-label {
      margin-right: 1em;
}
.cm-item-disabled {
       cursor: not-allowed;
       opacity: 0.3;
}

/*      ul {
        display: none;
      }*/
.context-menu:hover {
      background: rgba(0, 0, 0, 0.1);
}
.context-menu > ul {
                        position: absolute;
                        left: calc(100% + 2px);
                        top: 0;
                        display: block;
                        min-width: 100%;
}

